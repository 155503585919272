import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { colors as variableColors } from '../styles/variables';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    paddingTop: theme.spacing(3),
    backgroundColor: variableColors['color-gray-background'],
    height: '100vh',
    textAlign: 'center',
  },
}));

function NotFoundPage() {
  const classes = useStyles({});

  return (
    <div className={classes.container}>
      not found
    </div>
  );
}

export default NotFoundPage;
